import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{style:({ zIndex: _vm.options.zIndex }),attrs:{"max-width":_vm.options.width},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":_vm.options.color,"dense":"","flat":""}},[_c(VToolbarTitle,{staticClass:"text-body-2 font-weight-bold grey--text"},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"sm":"6"}},[_c('h4',{staticClass:"my-2 text-heading-4"},[_vm._v("Alte Werte:")]),_vm._l((_vm.oldLines),function(line){return _c('p',{key:line,staticClass:"my-1"},[(!Array.isArray(line.value))?_c('span',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(line.key)+":")]),_vm._v(" "+_vm._s(line.value))]):_c('span',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(line.key)+":")]),_vm._v(" "),_c('ul',_vm._l((line.value),function(item){return _c('li',[_vm._v(_vm._s(item))])}),0)])])})],2),_c(VCol,{attrs:{"sm":"6"}},[_c('h4',{staticClass:"my-2 text-heading-4"},[_vm._v("Neue Werte:")]),_vm._l((_vm.newLines),function(line){return _c('p',{key:line,staticClass:"my-1"},[(!Array.isArray(line.value))?_c('span',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(line.key)+":")]),_vm._v(" "+_vm._s(line.value))]):_c('span',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(line.key)+":")]),_vm._v(" "),_c('ul',_vm._l((line.value),function(item){return _c('li',[_vm._v(_vm._s(item))])}),0)])])})],2)],1)],1),_c(VCardActions,{staticClass:"pt-3"},[_c(VSpacer),_c(VBtn,{staticClass:"body-2 font-weight-bold",attrs:{"color":"primary"},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}},[_vm._v("Schließen")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }